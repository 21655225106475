import React, {FC, useEffect, useRef} from 'react'
import noop from 'lodash/noop'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {
  StatesButton,
  StatesButtonStates,
  TextField,
  Text,
  Checkbox,
  TextFieldTheme,
  ThreeDotsLoader,
} from 'wix-ui-tpa/cssVars'
import {useSettings, useStyles} from '@wix/tpa-settings/react'

import settingsParams from '../../settingsParams'
import stylesParams from '../../stylesParams'
import {RequestStatus} from '../../../../utils/wrapRequest'
import {PhoneInput} from '../../../../components-shared/PhoneInput'
import {FormError} from '../../../../components-shared/FormError'
import {LayoutSize} from '../../../../components-shared/LayoutSizeProvider/types'

import {useHooks} from './useHooks'
import {classes, st} from './Form.st.css'
import {PolicyLinks} from './PolicyLinks'
import {
  CUSTOM_FIELD_LENGTH_MAX,
  EMAIL_LENGTH_MAX,
  FIRST_NAME_LENGTH_MAX,
  LAST_NAME_LENGTH_MAX,
  reservationDetailsFormDataHooks,
} from './constants'

interface FormProps {
  layoutSize: LayoutSize
}

export const Form: FC<FormProps> = ({layoutSize}) => {
  const {t} = useTranslation()
  const settings = useSettings()
  const styles = useStyles()
  const isXS = layoutSize === LayoutSize.XS
  const {isEditor} = useEnvironment()
  const firstInputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    if (firstInputRef.current && !isEditor) {
      firstInputRef.current.focus()
    }
  }, [])

  const {
    currentReservationLocation,
    form,
    isManualApproval,
    submitReservationStatus,
    marketingCheckbox,
    handleMarketingCheckboxChange,
    handleTextFieldChange,
    handlePhoneNumberChange,
    handleCustomFieldChange,
    handleFormSubmit,
  } = useHooks()

  const onSubmit = async (e: React.FormEvent) => {
    await handleFormSubmit(e)

    const possibleNotValidInput = document.querySelector<HTMLInputElement>(
      `.${classes.textFieldError} input`,
    )

    if (possibleNotValidInput) {
      possibleNotValidInput.focus()
    }
  }

  const isSubmittingReservation = submitReservationStatus === RequestStatus.LOADING

  const textFieldTheme = settings.get(settingsParams.textFieldTheme) as TextFieldTheme

  const textFieldLayoutClass =
    textFieldTheme === TextFieldTheme.Box ? classes.boxFormFieldLayout : classes.lineFormFieldLayout
  const textFieldClass =
    textFieldTheme === TextFieldTheme.Box ? classes.boxFormField : classes.lineFormField

  const formRootClass = textFieldTheme === TextFieldTheme.Box ? classes.boxForm : classes.lineForm

  const formTitleTag =
    styles.get(stylesParams.subtitleFont).htmlTag ??
    styles.getDefaultValue(stylesParams.subtitleFont).htmlTag

  const buttonLabelText = settings.get(
    isManualApproval ? settingsParams.buttonLabelRequestText : settingsParams.buttonLabelText,
  )

  return (
    <section
      id="tr-form"
      className={st(classes.root, {[layoutSize]: true}, formRootClass)}
      data-hook={reservationDetailsFormDataHooks.root()}
    >
      <Text className={classes.formTitle} tagName={formTitleTag}>
        {t('uou-reservations.reservation-details.details.text')}
      </Text>

      <form className={classes.formBody} onSubmit={onSubmit} noValidate>
        <div className={classes.textFieldsSection}>
          <div className={textFieldLayoutClass}>
            <TextField
              inputRef={(input) => (firstInputRef.current = input)}
              className={st(
                textFieldClass,
                !form.firstName.valid ? classes.textFieldError : undefined,
              )}
              label={`${t('uou-reservations.reservation-details.firstname')}${
                form.firstName.isRequired ? '*' : ''
              }`}
              maxLength={FIRST_NAME_LENGTH_MAX}
              name="firstName"
              value={form.firstName.value}
              required={form.firstName.isRequired}
              onChange={handleTextFieldChange}
              theme={textFieldTheme}
              data-hook={reservationDetailsFormDataHooks.firstName()}
            />
            {!form.firstName.valid && (
              <Error text={t('uou-reservations.reservation-details.firstname.error')} />
            )}
          </div>

          <div className={textFieldLayoutClass}>
            <TextField
              className={st(
                textFieldClass,
                !form.lastName.valid ? classes.textFieldError : undefined,
              )}
              label={`${t('uou-reservations.reservation-details.lastname')}${
                form.lastName.isRequired ? '*' : ''
              }`}
              maxLength={LAST_NAME_LENGTH_MAX}
              name="lastName"
              value={form.lastName.value}
              required={form.lastName.isRequired}
              onChange={handleTextFieldChange}
              theme={textFieldTheme}
              data-hook={reservationDetailsFormDataHooks.lastName()}
            />
            {!form.lastName.valid && (
              <Error text={t('uou-reservations.reservation-details.lastname.error')} />
            )}
          </div>

          <div className={textFieldLayoutClass}>
            <div>
              <PhoneInput
                label={`${t('uou-reservations.reservation-details.phone')}*`}
                value={form.phoneNumber.value}
                onChange={handlePhoneNumberChange}
                wrapperClassName={classes.phoneWrapper}
                labelClassName={classes.phoneInputLabel}
                className={textFieldClass}
                invalid={!form.phoneNumber.valid}
                textFieldTheme={textFieldTheme}
                defaultCountry={currentReservationLocation?.location?.address?.country!}
                dataHook={reservationDetailsFormDataHooks.phoneNumber()}
              />

              {!form.phoneNumber.valid && (
                <Error text={t('uou-reservations.reservation-details.phone.error')} />
              )}
            </div>
          </div>

          <div className={textFieldLayoutClass}>
            <TextField
              className={st(textFieldClass, !form.email.valid ? classes.textFieldError : undefined)}
              label={`${t('uou-reservations.reservation-details.email')}${
                form.email.isRequired ? '*' : ''
              }`}
              maxLength={EMAIL_LENGTH_MAX}
              name="email"
              value={form.email.value}
              required={form.email.isRequired}
              onChange={handleTextFieldChange}
              theme={textFieldTheme}
              data-hook={reservationDetailsFormDataHooks.email()}
            />
            {!form.email.valid && (
              <Error text={t('uou-reservations.reservation-details.email.error')} />
            )}
          </div>

          {currentReservationLocation?.configuration?.reservationForm?.customFieldDefinitions?.map(
            ({id, name}) => (
              <div className={st(textFieldLayoutClass, classes.customFieldLayout)} key={id}>
                <TextField
                  className={st(
                    textFieldClass,
                    !form.customFields[id!]?.valid ? classes.textFieldError : undefined,
                  )}
                  label={`${name}${form.customFields[id!]?.isRequired ? '*' : ''}`}
                  maxLength={CUSTOM_FIELD_LENGTH_MAX}
                  name={id!}
                  value={form.customFields[id!]?.value}
                  required={form.customFields[id!]?.isRequired}
                  onChange={handleCustomFieldChange}
                  theme={textFieldTheme}
                />
                {!form.customFields[id!]?.valid && (
                  <Error text={t('uou-reservations.reservation-details.custom.error')} />
                )}
              </div>
            ),
          )}
        </div>

        {isManualApproval ? (
          <Text className={classes.manualApprovalText} tagName="p">
            {t('uou-reservations.reservation-details.request-note')}
          </Text>
        ) : null}

        {currentReservationLocation?.configuration?.reservationForm?.submitMessage && (
          <Text className={classes.submitMessage} tagName="p">
            {currentReservationLocation?.configuration?.reservationForm?.submitMessage}
          </Text>
        )}

        {currentReservationLocation?.configuration?.reservationForm?.emailMarketingCheckbox
          ?.enabled && (
          <Checkbox
            className={classes.marketingCheckbox}
            checked={marketingCheckbox}
            label={t('uou-reservations.reservation-details.marketing.checkbox')}
            onChange={handleMarketingCheckboxChange}
          />
        )}

        <PolicyLinks reservationLocation={currentReservationLocation} />

        <StatesButton
          type="submit"
          disabled={isSubmittingReservation}
          state={isSubmittingReservation ? StatesButtonStates.IN_PROGRESS : StatesButtonStates.IDLE}
          inProgressContent={<ThreeDotsLoader className={classes.loader} />}
          idleContent={buttonLabelText}
          className={classes.submitButton}
          data-hook={reservationDetailsFormDataHooks.completeReservation()}
          // glitch in WUT. It always takes onClick as a prop despite type="submit" on button
          onClick={noop}
        />
      </form>
    </section>
  )
}

export const Error: FC<{text: string}> = ({text}) => {
  const settings = useSettings()

  const textFieldTheme = settings.get(settingsParams.textFieldTheme) as TextFieldTheme
  const errorClass =
    textFieldTheme === TextFieldTheme.Box ? classes.boxFormError : classes.lineFormError

  return <FormError text={text} className={errorClass} />
}
